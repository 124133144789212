import React from 'react'

class Filter extends React.Component {
  render() {
    return (
      <div>Filter</div>
    )
  }
}

export default Filter;