import React from 'react';
import "./Footer.css";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="row container">
          <div className="footer__left">
            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="169" viewBox="0 0 61 169" fill="none">
              <path d="M0 0V168.932H61V0H0ZM56.7073 164.54H4.30943V4.39153H56.724L56.7073 164.54Z" fill="white" />
              <path d="M25.175 99.2176H28.8497V110.386C28.8497 110.813 28.6908 111.223 28.4054 111.534L25.616 114.337V119.029L30.6603 113.875C31.1177 113.432 31.4815 112.897 31.7291 112.304C31.9767 111.711 32.103 111.072 32.1001 110.427V100.889L30.4833 99.2347H38.5676V95.8992H32.1001V94.2451H28.8597V95.8992H22.3923V100.889C22.9626 100.905 23.5259 100.757 24.018 100.462C24.51 100.166 24.9109 99.735 25.175 99.2176Z" fill="white" />
              <path d="M25.2585 26.93H28.9332V38.0985C28.9342 38.5253 28.7752 38.9363 28.4889 39.2468L25.6995 42.0526V46.7414L30.7372 41.5912C31.194 41.1472 31.5571 40.6121 31.8041 40.0191C32.0512 39.4261 32.1769 38.7876 32.1737 38.1429V28.5739L30.5568 26.9198H38.6411V23.6048H32.1737V21.9507H28.9332V23.6048H22.4658V28.5739C23.0351 28.5951 23.5987 28.4522 24.0925 28.1616C24.5863 27.8709 24.9903 27.4442 25.2585 26.93Z" fill="white" />
              <path d="M28.0546 57.2368V60.5621C28.4798 60.5643 28.9014 60.4803 29.2947 60.3149C29.688 60.1494 30.0454 59.9058 30.3461 59.5982C30.6468 59.2905 30.8849 58.925 31.0467 58.5226C31.2084 58.1202 31.2905 57.689 31.2883 57.2539L28.0546 57.2368Z" fill="white" />
              <path d="M31.2883 65.5103H32.9052V62.1987H28.0546V76.7266C28.0511 77.3713 28.1768 78.0099 28.4238 78.6029C28.6709 79.196 29.0341 79.731 29.4911 80.1749L32.9052 83.6677V78.9788L31.736 77.8305C31.4483 77.5193 31.2881 77.1071 31.2883 76.6788V67.1678L29.6715 65.5103H31.2883Z" fill="white" />
              <path d="M32.481 138.024V141.311H35.5777C35.5863 140.881 35.5107 140.454 35.3552 140.054C35.1997 139.654 34.9675 139.291 34.6724 138.984C34.3934 138.676 34.054 138.431 33.6765 138.266C33.2989 138.1 32.8916 138.018 32.481 138.024Z" fill="white" />
              <path d="M18.7277 144.634C18.4337 144.941 18.2025 145.305 18.0476 145.704C17.8927 146.104 17.8173 146.531 17.8257 146.961H21.5339C22.0837 146.957 22.6261 146.831 23.124 146.592C23.6327 146.318 24.0861 145.948 24.4602 145.502L25.9736 143.67L32.2673 135.468H34.3051C34.4943 135.475 34.6803 135.52 34.8523 135.601C35.0244 135.682 35.179 135.797 35.3073 135.939L39.0922 140.874C39.3843 141.271 39.5942 141.724 39.7091 142.207C39.824 142.689 39.8414 143.191 39.7604 143.68H31.833C31.2828 143.684 30.7401 143.811 30.2429 144.053C29.7334 144.325 29.2798 144.695 28.9066 145.143L27.3933 146.971H39.6535C39.6535 146.971 41.3238 147.087 42.326 145.823C43.0643 144.904 43.1611 144.271 43.1611 143.089C43.1611 141.38 42.8271 141.165 42.4028 140.516L37.1246 133.633C36.8231 133.236 36.4556 132.897 36.0389 132.631C35.5559 132.341 35.0087 132.181 34.4487 132.167H31.1883L31.1382 132.231V132.167H28.0414C28.033 132.596 28.1084 133.023 28.2633 133.423C28.4181 133.823 28.6493 134.187 28.9434 134.494C29.0336 134.586 29.1271 134.658 29.2206 134.74L26.7052 138.017L23.6017 142.067C23.4685 141.803 23.2986 141.56 23.0973 141.346C22.8183 141.038 22.4795 140.794 22.1026 140.629C21.7258 140.463 21.3192 140.381 20.9091 140.386H17.8124C17.804 140.815 17.8794 141.243 18.0342 141.642C18.1891 142.042 18.4203 142.406 18.7144 142.713C18.9936 143.02 19.3324 143.264 19.7093 143.43C20.0861 143.595 20.4925 143.678 20.9025 143.673C20.4947 143.67 20.0908 143.754 19.7164 143.919C19.342 144.085 19.0053 144.328 18.7277 144.634Z" fill="white" />
            </svg>
            <nav className="nav__links">
              <div className="links__link-wrapper">
                <a href="#" className="wrapper__link"><div className="before"></div>Про нас</a>
              </div>
              <div className="links__link-wrapper">
                <a href="#" className="wrapper__link"><div className="before"></div>Новини</a>
              </div>
              <div className="links__link-wrapper">
                <a href="#" className="wrapper__link"><div className="before"></div>Контакти</a>
              </div>
              <div className="links__link-wrapper">
                <a href="#" className="wrapper__link"><div className="before"></div>Профайл</a>
              </div>
              <div className="links__link-wrapper last">
                <a href="#" className="wrapper__link"><div className="before"></div>Часті запитання</a>
              </div>
            </nav>
          </div>
          <div className="footer__right">
            <div className="footer__right-top">
              <nav className="footer__social-media">
                <a href="#" className="insta">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path d="M28.25 0H3.75C1.68225 0 0 1.68225 0 3.75V28.25C0 30.3177 1.68225 32 3.75 32H28.25C30.3177 32 32 30.3177 32 28.25V3.75C32 1.68225 30.3177 0 28.25 0ZM29.5 28.25C29.5 28.9393 28.9393 29.5 28.25 29.5H21.125V19.3125H24.9869L25.625 15.4375H21.125V12.75C21.125 11.6891 21.9391 10.875 23 10.875H25.5625V7H23C19.8173 7 17.2514 9.57812 17.2514 12.7609V15.4375H13.5V19.3125H17.2514V29.5H3.75C3.06075 29.5 2.5 28.9393 2.5 28.25V3.75C2.5 3.06075 3.06075 2.5 3.75 2.5H28.25C28.9393 2.5 29.5 3.06075 29.5 3.75V28.25Z" fill="white" />
                  </svg>
                </a>
                <a href="#" className="facebook">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path d="M22.7046 0H9.2955C4.16994 0 0 4.16994 0 9.2955V22.7045C0 27.8301 4.16994 32 9.2955 32H22.7045C27.8301 32 32 27.8301 32 22.7046V9.2955C32 4.16994 27.8301 0 22.7046 0ZM29.5 22.7045C29.5 26.4516 26.4516 29.5 22.7046 29.5H9.2955C5.54844 29.5 2.5 26.4516 2.5 22.7046V9.2955C2.5 5.54844 5.54844 2.5 9.2955 2.5H22.7045C26.4516 2.5 29.5 5.54844 29.5 9.2955V22.7045Z" fill="white" />
                    <path d="M16 7.375C11.2441 7.375 7.375 11.2441 7.375 16C7.375 20.7559 11.2441 24.625 16 24.625C20.7559 24.625 24.625 20.7559 24.625 16C24.625 11.2441 20.7559 7.375 16 7.375ZM16 22.125C12.6227 22.125 9.875 19.3773 9.875 16C9.875 12.6227 12.6227 9.875 16 9.875C19.3773 9.875 22.125 12.6227 22.125 16C22.125 19.3773 19.3773 22.125 16 22.125Z" fill="white" />
                    <path d="M24.75 8.5C25.4404 8.5 26 7.94036 26 7.25C26 6.55964 25.4404 6 24.75 6C24.0596 6 23.5 6.55964 23.5 7.25C23.5 7.94036 24.0596 8.5 24.75 8.5Z" fill="white" />
                  </svg>
                </a>
              </nav>
            </div>
            <div className="footer__right-bottom">
              <p className="footer__info-paragraph">Працюємо з 11:00 - 22:30</p>
              <h3 className="footer__info-number">+38 093 8311 778</h3>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;